LiveForm.setOptions({
    messageErrorClass: "text error",
    wait: 500
});

if (window.getImageSupport) {
    getImageSupport(function (support) {
        document.documentElement.className += support.avif ? " avif": " no-avif";
        document.documentElement.className += support.webp ? " webp": " no-webp";
    });
}

$(function () {

    var $window = $(window);
    var $document = $(document);

    function initReferencesSlider() {
        $(".refs-slider").slick({
            dots: true, 
            arrows: false, 
            infinite: true, 
            speed: 300,
            slidesToShow: 1, 
            slidesToScroll: 1 
        }); 
    }

    function initMenuToggle() {
        var $menuBtn = $(".menu-btn");
        var $siteNav = $(".site-header .nav");
        var openedBtnClass = "menu--open";
        var openedNavClass = "nav--open";

        $menuBtn.on("click", function () { 
            $menuBtn.toggleClass(openedBtnClass);
            $siteNav.toggleClass(openedNavClass);
        }); 
    
        $(".site-header a:not(.nolink)").on("click", function () { 
            $menuBtn.removeClass(openedBtnClass);
            $siteNav.removeClass(openedNavClass);
        });

        $window.on("resize", function () {
            if ($menuBtn.hasClass(openedBtnClass) && $menuBtn.css("display") === "none") {
                $menuBtn.click();
            }
        });
    }

    function initScrollToHash() {
        var $scrollable = $("html, body");
        var $siteHeaderWrapper = $(".site-header__wrapper");
        var stickyHeaderPadding = 4;

        $document.on("click", "a", function (event) { 
            var hash = this.hash; 
            var $hash = this.hash ? $(hash): null;

            if ("" !== hash && $hash && $hash.length) {
                event.preventDefault();
                $hash.attr("id", "");
                
                var offsetTop = $hash.offset().top;
                var scrollTo = offsetTop - $siteHeaderWrapper.outerHeight() - stickyHeaderPadding * 2;
                $scrollable.stop().animate({ scrollTop: scrollTo }, {
                    duration: 800,
                    complete: function () { 
                        requestAnimationFrame(function () {
                            if (window.history) {
                                history.replaceState(null, null, hash);
                            } else {
                                window.location.hash = hash;
                            }
                            requestAnimationFrame(function () {
                                $hash.attr("id", hash.substring(1));
                            });
                        });
                    }
                });
            }
        });
    }

    function initStickyHeader() {
        var siteHeaderIsSticky = false;
        var $siteHeader = $(".site-header");
        $siteHeader.addClass("site-header--sticky-init");

        $window.on("scroll", function () {
            if ($window.scrollTop() > 0) {
                if (!siteHeaderIsSticky) {
                    $siteHeader.addClass("site-header--sticky");
                    siteHeaderIsSticky = true;
                }
            } else if (siteHeaderIsSticky) {
                $siteHeader.removeClass("site-header--sticky");
                siteHeaderIsSticky = false;
            }
        });
        if ($window.scrollTop() > 0) {
            $window.trigger("scroll");
        }
    }

    function initEqHeight() {
        
        var $elements = $("[data-eq-height]");
        var groups = {};

        $elements.each(function () {
            var options = this.getAttribute("data-eq-height").split(":");
            var name = options.shift();
            var mq = options.join(":");
            groups[name] = groups[name] || { items: [], mq: window.matchMedia(mq) };
            groups[name].items.push(this);
        });

        function onResize() {
            $.each(groups, function (g, group) {
                group.items.forEach(function (item) {
                    item.style.minHeight = "";
                });
                if (group.mq.matches) {
                    var heights = group.items.map(function (item) {
                        return item.offsetHeight;
                    });
                    var maxHeight = Math.max.apply(Math, heights);
                    group.items.forEach(function (item) {
                        item.style.minHeight = maxHeight + "px";
                    });
                }
            });
        }

        window.addEventListener("resize", onResize);
        onResize();
    }

    function initFormToggle() {
        $document.on("change", "[data-form-toggle]", function (event) {
            var toggleEl = event.target.form.querySelector("[name='" + event.currentTarget.getAttribute("data-form-toggle") + "']");
            if (toggleEl && toggleEl.parentElement) {
                toggleEl.parentElement.hidden = !event.target.checked;
            }
        });
        $("input[data-form-toggle], [data-form-toggle] input").change();
    }

    initStickyHeader();
    initReferencesSlider();
    initMenuToggle();
    initScrollToHash();
    initEqHeight();
    initFormToggle();

    // because otherwise "scroll-to-hash" does not work properly
    if (document.querySelector(".hero--hp")) { 
        if (window.requestIdleCallback) {
            requestIdleCallback(function () {
                $("[loading='lazy'").attr("loading", "eager");
            });
        } else {
            $("[loading='lazy'").attr("loading", "eager");
        }
    }

    // ?
    $window.on("click", function (event) { 
        if ("nolink dropdown__trigger" == $(event.target).attr("class")) {
            $(event.target).parent().toggleClass("dropdown--active");
        } else {
            $(".dropdown").removeClass("dropdown--active");
        }
    });
});
