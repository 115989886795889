(function () {
	var $viewportMeta = $("meta[name='viewport']");
	var selector = "input:not(:disabled, [type='button']), select:not(:disabled), textarea:not(:disabled)";
	var metaZoomable = "width=device-width, initial-scale=1";
	var metaNonZoomable = "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0";
	var timeout;
	$(document).on("touchend", selector, function () {
		clearTimeout(timeout);
		timeout = setTimeout(function () { $viewportMeta.attr("content", metaZoomable); }, 1000 / 60);
		$viewportMeta.attr("content", metaNonZoomable);
	});
}());


