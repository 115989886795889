$(function () {

	if (document.documentElement.className.indexOf("no-intersection-observer") > -1) {
		return;
	}

	var $window = $(window);

	var IS_INTERSECTING_CLASS = "is-intersecting";
	var ANIMATION_TRIGGERS_SELECTOR = ".animation-trigger";

	function preloadImgs(elements) {

		function onLoadImgIntersect(entries) {
			var observer = this;
			entries.forEach(function (entry) {
				if (entry.isIntersecting) {
					if (entry.target.getAttribute("loading") === "lazy") {
						entry.target.setAttribute("loading", "eager");
					}
					observer.unobserve(entry.target);
				}
			});
		}
		
		var loadImgObserver = new IntersectionObserver(onLoadImgIntersect, {
			rootMargin: "80% 0% 80% 0%"
		});
	
		var imgsToLoad = elements.map(function (element) { return element.querySelectorAll("[loading='lazy']"); })
			.reduce(function (result, imgs) { return result.concat(Array.prototype.slice.call(imgs)); }, []);

		imgsToLoad.filter(function (img, i) { return imgsToLoad.indexOf(img) === i; })
			.forEach(function (img) { loadImgObserver.observe(img); });
	}

	function observeTriggers(elements, onIntersectFns) {
		
		function onAnimIntersect(entries) {
			var observer = this;
			entries.forEach(function (entry) {
				if (entry.isIntersecting) {
					if (onIntersectFns && onIntersectFns.length) {
						onIntersectFns.forEach(function (fn) { fn(entry); });
					}
					entry.target.className += " " + IS_INTERSECTING_CLASS;
					observer.unobserve(entry.target);
				}
			});
		}
		
		var animObserver = new IntersectionObserver(onAnimIntersect, {
			rootMargin: "-20% 0% -20% 0%"
		});
		
		elements.forEach(function (element) { animObserver.observe(element); });

		return animObserver;
	}

	var elements = Array.prototype.slice.call(document.querySelectorAll(ANIMATION_TRIGGERS_SELECTOR));

	var onIntersectFns = [];
	var animObserver = observeTriggers(elements, onIntersectFns);
	preloadImgs(elements);

	// LOTTIE ->

	var LOTTIE_SRC = "assets/js/libs/lottie-web-master/build/player/lottie.min.js";

	$.getScript(LOTTIE_SRC).then(function () {

		// LOGO ->

		var LOGO = document.querySelector(".site-header__logo-link");
		var LOGO_MOBILE_MQ = window.matchMedia("(max-width: 1015px)"); 
		var LOGO_LOTTIE_FILES = {
			mobile: "assets/img/lottie/logo-mobile.json",
			desktop: "assets/img/lottie/logo.json"
		};
		var LOGO_VIEWBOX = {
			mobile: "30 48 1208 124",
			desktop: "16 44 1526 242"
		};

		function loadLogoAnim(path, viewBox) {

			var logoAnim = lottie.loadAnimation({
				container: LOGO,
				renderer: "svg",
				loop: true,
				autoplay: true,
				path: path
			});
	
			logoAnim.addEventListener("data_ready", function () {
				var img = LOGO.querySelector("img");
				var svg = logoAnim.renderer.svgElement;
				img.style.opacity = 0;
				svg.setAttribute("viewBox", viewBox);
				svg.setAttribute("aria-hidden", "true");
			});

			return logoAnim;
		}

		var desktopLogoAnim = LOGO_MOBILE_MQ.matches ? null: loadLogoAnim(LOGO_LOTTIE_FILES.desktop, LOGO_VIEWBOX.desktop);
		var mobileLogoAnim = LOGO_MOBILE_MQ.matches ? loadLogoAnim(LOGO_LOTTIE_FILES.mobile, LOGO_VIEWBOX.mobile): null;
		var lastLogoStateIsMobile = LOGO_MOBILE_MQ.matches;

		$window.on("resize", function () {
			if (lastLogoStateIsMobile === LOGO_MOBILE_MQ.matches) {
				return;
			}
			lastLogoStateIsMobile = LOGO_MOBILE_MQ.matches;
			if (desktopLogoAnim) {
				desktopLogoAnim = desktopLogoAnim || loadLogoAnim(LOGO_LOTTIE_FILES.desktop, LOGO_VIEWBOX.desktop);
				desktopLogoAnim.renderer.svgElement.style.display = LOGO_MOBILE_MQ.matches ? "none": "";
				desktopLogoAnim[LOGO_MOBILE_MQ.matches ? "stop": "play"]();
			}
			if (mobileLogoAnim) {
				mobileLogoAnim.renderer.svgElement.style.display = LOGO_MOBILE_MQ.matches ? "": "none";
				mobileLogoAnim[LOGO_MOBILE_MQ.matches ? "play": "stop"]();
			}
			if (!desktopLogoAnim && !LOGO_MOBILE_MQ.matches) {
				desktopLogoAnim = loadLogoAnim(LOGO_LOTTIE_FILES.desktop, LOGO_VIEWBOX.desktop);
			}
			if (!mobileLogoAnim && LOGO_MOBILE_MQ.matches) {
				mobileLogoAnim = loadLogoAnim(LOGO_LOTTIE_FILES.mobile, LOGO_VIEWBOX.mobile);
			}
		});

		// HOW IT WORKS ->

		var HOW_IT_WORKS = document.querySelector(".howitworks");

		if (HOW_IT_WORKS) {

			var HOW_IT_WORKS_IMGS = Array.prototype.slice.call(document.querySelectorAll(".howitworks__col__img img"));
			var HOW_IT_WORKS_STAGGER = 1000;
		
			var howItWorksLoadedCount = 0;
			var howItWorksAnims = {};
			var howItWorksAnimRun = false;

			function onAllHowItWorksAnimsLoaded() {

				function runAnimations() {
					if (howItWorksAnimRun) return;
					howItWorksAnimRun = true;
					$.each(howItWorksAnims, function (i, animation) {
						setTimeout(function () { animation.play(); }, HOW_IT_WORKS_STAGGER * i);
					});
				}

				if ($(HOW_IT_WORKS).hasClass(IS_INTERSECTING_CLASS)) {
					return runAnimations();
				} 

				onIntersectFns.push(function (entry) {
					if (!howItWorksAnimRun && (entry.target === HOW_IT_WORKS || HOW_IT_WORKS.contains(entry.target))) {
						runAnimations();
					}
					if (howItWorksAnimRun) {
						onIntersectFns.splice(onIntersectFns.indexOf(arguments.callee), 1);
					}
				});
			}

			HOW_IT_WORKS_IMGS.forEach(function (img, i) {

				howItWorksAnims[i] = lottie.loadAnimation({
					container: img.parentElement,
					renderer: "svg",
					loop: false,
					autoplay: false,
					path: img.getAttribute("src").replace(/\.svg$/i, ".json")
				});

				howItWorksAnims[i].addEventListener("data_ready", function () {
					img.parentElement.removeChild(img);
					var svg = howItWorksAnims[i].renderer.svgElement;
					svg.setAttribute("role", "img");
					svg.setAttribute("aria-label", img.alt);
				});

				if ((i + 1) === HOW_IT_WORKS_IMGS.length) {
					howItWorksAnims[i].addEventListener("complete", function () {
						$.each(howItWorksAnims, function (i, animation) {
							setTimeout(function () { 
								animation.goToAndStop(0); 
								animation.play(); 
							}, HOW_IT_WORKS_STAGGER * i);
						});
					});
				}

				howItWorksAnims[i].addEventListener("DOMLoaded", function () {
					howItWorksLoadedCount++;
					if (howItWorksLoadedCount === HOW_IT_WORKS_IMGS.length) {
						onAllHowItWorksAnimsLoaded();
					}
				});
			});
		}
	});

	// DEBUG

	// var btns = [$("<button>toggle</button>"), $("<button>reset</button>")];
	// btns.forEach(function (el, i) { $(el).css({ position: "fixed", top: 0, left: 100 * i, zIndex: 1000000, height: "50px", width: "100px", appearance: "none" }); });
	// btns[0].on("click", function () { $(ANIMATION_TRIGGERS_SELECTOR).toggleClass(IS_INTERSECTING_CLASS); });
	// btns[1].on("click", function () {
	// 	elements.forEach(function (element) { animObserver.unobserve(element); });
	// 	$(ANIMATION_TRIGGERS_SELECTOR).removeClass(IS_INTERSECTING_CLASS);
	// 	$window.off("scroll._debug").one("scroll._debug", function () { elements.forEach(function (element) { animObserver.observe(element); }); });
	// });
	// $(document.body).append(btns);
});