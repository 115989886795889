(function() {
    
    var support = { webp: false, avif: false };
    var running = false;
    var avifDone = false;
    var webpDone = false;
    var cbs = [];

    function onDone() {
        if (avifDone && webpDone) {
            running = false;
            cbs.forEach(function (cb) {
                cb(support);
            });
        } 
    }

    window.getImageSupport = function getImageSupport(cb) {
        
        if (!running && avifDone && webpDone) {
            return cb(support);
        }

        if (running) {
            if (typeof cb === "function") {
                cbs.push(cb);         
            }
            return;
        }
        
        if (typeof cb === "function") {
            cbs.push(cb);         
        }

        if (!window.createImageBitmap || !window.Promise || !window.fetch) {
            avifDone = true;
            webpDone = true;
            return onDone();
        }
        
        running = true;

        var avifData = 'data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUEAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAF0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgS0AAAAAABNjb2xybmNseAACAAIAAIAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAAGVtZGF0EgAKBzgAPtAgIAkyUBAAAPWc41TP///4gHBX9H8XVK7gGeDllq8TYARA+8Tfsv7L+zPE24eIoIzE0WhHbrqcrTK9VEgEG/hwgB5rdCbvP8g3KYPdV88CvPJnptgQ';
        var webpData = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';
        
        Promise.all([fetch(avifData), fetch(webpData)]).then(function (data) {
            Promise.all([data[0].blob(), data[1].blob()]).then(function (results) {

                var avifPromise = createImageBitmap(results[0]);
                var webpPromise = createImageBitmap(results[1]);
                
                avifPromise
                    .then(function () {
                        support.avif = true;
                    })
                    .finally(function () {
                        avifDone = true;
                        onDone();
                    });

                webpPromise
                    .then(function () {
                        support.webp = true;
                    })
                    .finally(function () {
                        webpDone = true;
                        onDone();
                    });
            });
        });
    };
}());